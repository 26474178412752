import { ObjectDeserializer } from '@sebbia/object-deserializer';
import { coordinatesDeserializer } from 'shared/model';
import { Money } from 'shared/utils';
import { Map, PlacePrice, PlaceStatus, RemotePlace } from './types';

export function mapDeserializer(o: ObjectDeserializer): Map {
  return {
    src: o.required('map.publicLink').asString,
    size: {
      width: o.required('size.width').asNumber,
      height: o.required('size.height').asNumber,
    },
  };
}

export function placeDeserializer(o: ObjectDeserializer): RemotePlace {
  const rowNumber = o.optional('rowNumber')?.asString
      ?? o.required('row.number').asString;
  const sectorTitle = o.optional('sectorTitle')?.asString
      ?? o.required('row.sector.title').asString;
  return {
    id: o.required('id').asString,
    number: o.required('number').asString,
    coordinates: o.required('coordinates').asObject(coordinatesDeserializer),
    rowNumber: rowNumber,
    sectorTitle: sectorTitle,
  };
}

export function placePriceDeserializer(o: ObjectDeserializer): PlacePrice {
  const price = o.required('price').as(value => Money.parse(String(value)));
  return {
    place: o.required('place').asObject(placeDeserializer),
    status: o.required('status').asEnum(PlaceStatus),
    color: o.required('color').asString,
    price,
    priceWithDiscount: o.optional('priceWithDiscount')?.as(value => Money.parse(String(value))) || price,
    priceCategoryId: o.optional('priceCategoryId')?.asString,
  };
}
