import { ApolloClient, FetchResult, gql, NormalizedCacheObject } from '@apollo/client';
import { deserialize } from '@sebbia/object-deserializer';
import { PreferenceKeys, PreferencesService } from 'processes/Preferences';
import { ID } from 'shared/model';
import { mapDeserializer, placePriceDeserializer } from './deserializers';
import { Map, PlacePrice, PlacePriceSubscription, SeasonTicketSubscription, VenueService } from './types';

class VenueServiceImpl implements VenueService {
  apiUrl: string;

  constructor(
    private client: ApolloClient<NormalizedCacheObject>,
    preferencesService: PreferencesService
  ) {
    this.apiUrl = preferencesService.get(PreferenceKeys.GRAPHQL_ENDPOINT);
  }

  async getMapMatch(id: ID): Promise<Map> {
    const query = gql`
      query getMapMatch($id: ID!) {
        match {
          getById(id: $id) {
            venue {
              map {
                publicLink
              }
              size {
                width
                height
              }
            }
          }
        }
      }
    `;
    const res = await this.client.query({ query, variables: { id }, fetchPolicy: 'cache-first' });
    return deserialize(res.data, o => o.required('match.getById.venue').asObject(mapDeserializer));
  }

  async getMapSeasonTicket(id: ID): Promise<Map> {
    const query = gql`
      query getMapSeasonTicket($id: ID!) {
        seasonTicketDescriptor {
          getById(id: $id) {
            venue {
              map {
                publicLink
              }
              size {
                width
                height
              }
            }
          }
        }
      }
    `;
    const res = await this.client.query({ query, variables: { id }, fetchPolicy: 'cache-first' });
    return deserialize(res.data, o =>
      o.required('seasonTicketDescriptor.getById.venue').asObject(mapDeserializer)
    );
  }

  async oldGetMatchPlaces(id: string): Promise<PlacePrice[]> {
    const query = gql`
      query getMatchPlaces($filter: PlacePriceFilter!) {
        price {
          getPlaces(filter: $filter) {
            list {
              place {
                id
                number
                coordinates {
                  x
                  y
                }
                row {
                  number
                  sector {
                    title
                  }
                }
              }
              price
              status
              color
              priceCategoryId
            }
          }
        }
      }
    `;

    const res = await this.client.query({
      query,
      variables: {filter: {eventId: id, tag: 'ONLINE'}},
      fetchPolicy: 'no-cache',
    });

    return deserialize(res.data, o =>
      o.required('price.getPlaces.list').asArrayOfObjects(placePriceDeserializer)
    );
  }

  async getMatchPlaces(id: string): Promise<PlacePrice[]> {
    try {
      const restRes = await (await fetch(`${this.apiUrl}/placePrice/${id}/online`)).json();
      return deserialize(restRes, o =>
        o.required('placePrices').asArrayOfObjects(placePriceDeserializer)
      );
    } catch (e) {
      console.error("<6d98c42e> Can`t fetch match places from rest", e)
      return await this.oldGetMatchPlaces(id);
    }
  }

  async oldGetSeasonTicketPlaces(id: string): Promise<PlacePrice[]> {
    const query = gql`
      query getSeasonTicketPlaces($filter: SeasonTicketPlaceFilterInput, $id: ID!) {
        seasonTicketDescriptor {
          getById(id: $id) {
            places(filter: $filter) {
              place {
                id
                number
                coordinates {
                  x
                  y
                }
                row {
                  number
                  sector {
                    title
                  }
                }
              }
              price
              status
              color
              priceCategory {
                id
                title
              }
            }
          }
        }
      }
    `;

    const res = await this.client.query({
      query,
      variables: { id },
    });
    return deserialize(res.data, o =>
      o.required('seasonTicketDescriptor.getById.places').asArrayOfObjects(placePriceDeserializer)
    );
  }

  async getSeasonTicketPlaces(id: string): Promise<PlacePrice[]> {
    try {
      const restRes = await (
        await fetch(`${this.apiUrl}/seasonTicketPlacePrice/${id}`)
      ).json();
      return deserialize(restRes, o =>
        o.required('placePrices').asArrayOfObjects(placePriceDeserializer)
      );
    } catch (e) {
      console.error("<f9b56f35> Can`t fetch season ticket places from rest", e)
      return await this.oldGetSeasonTicketPlaces(id);
    }
  }

  eventPlacesStatusSubscription({
                                  filter,
                                  onNext,
                                  onError,
                                  onComplete
                                }: PlacePriceSubscription): ZenObservable.Subscription {
    const PLACE_PRICE_STATUS_CHANGE = gql`
      subscription eventPlaceStatusSubscription($filter: PlacePriceFilter!) {
        eventPlaceStatusChanges(filter: $filter) {
          place {
            id,
            number,
            coordinates {
              x,
              y
            },
            row {
              id,
              svgId,
              number,
              sector {
                id,
                title,
                svgId,
                venue {
                  id,
                  title,
                  status,
                  map {
                    id,
                    publicLink,
                  }
                }
              },
            },
          },
          price,
          priceWithDiscount,
          status,
          color,
        }
      }
    `;
    return this.client.subscribe({
      query: PLACE_PRICE_STATUS_CHANGE,
      variables: {filter},
    }).subscribe({
        next(value: FetchResult<PlacePrice>) {
          const placePrice = deserialize(value.data, (o) => o.required('eventPlaceStatusChanges').asObject(placePriceDeserializer));
          onNext(placePrice);
        },
        error(errorValue: any) {
          onError ? onError(errorValue) : console.error(errorValue);
        },
        complete() {
          onComplete && onComplete();
        }
      }
    );
  }

  seasonTicketPlacesStatusSubscription({filter, onNext, onError, onComplete}: SeasonTicketSubscription): ZenObservable.Subscription {
    const SEASON_TICKET_PLACE_STATUS_CHANGES = gql`
      subscription seasonTicketPlaceStatusChanges($filter: SeasonTicketSubscriptionFilter!) {
        seasonTicketPlaceStatusChanges(filter: $filter) {
          place {
            id,
            number,
            coordinates {
              x,
              y
            },
            row {
              id,
              svgId,
              number,
              sector {
                id,
                title,
                svgId,
                venue {
                  id,
                  title,
                  status,
                  map {
                    id,
                    publicLink,
                  }
                }
              },
            },
          },
          price,
          status,
          color,
        }
      }
    `;

    return this.client.subscribe({
      query: SEASON_TICKET_PLACE_STATUS_CHANGES,
      variables: {filter},
    }).subscribe({
      next(value: FetchResult<PlacePrice>) {
        const placePrice = deserialize(value.data, (o) => o.required('seasonTicketPlaceStatusChanges').asObject(placePriceDeserializer));
        onNext(placePrice);
      },
      error(errorValue: any) {
        onError ? onError(errorValue) : console.error(errorValue);
      },
      complete() {
        onComplete && onComplete();
      }
    });
  }
}


export default VenueServiceImpl;
