import React, {useEffect, useState} from 'react';
import {Path} from 'routes/path';
import {AuthorizationState, AuthService} from 'processes/Auth';
import {AuthWithSwitch, ProfileService} from 'entities/Profile';
import {OrderItemDescriptor, OrderService} from 'entities/Order';
import {PreferencesService} from 'processes/Preferences';
import {useTranslation} from 'react-i18next';
import {classNames, minDate, useBehaviorSubject, useServiceData, useWindowSize,} from 'shared/utils';
import IconWithCounter from '../IconWithCounter/IconWithCounter';
import {CountdownTimerOnRxJS, DelayUnmountChild, Modal} from 'shared/ui';
import MobileNavigation from '../MobileNavigation/MobileNavigation';
import NavList from '../NavList/NavList';
import {BurgerIcon} from 'shared/assets/icons/BurgerIcon/BurgerIcon';

import {ReactComponent as ShoppingCart} from 'shared/assets/icons/shopping_cart.svg';
import {ReactComponent as Ticket} from 'shared/assets/icons/ticket.svg';
import {ReactComponent as Smile} from 'shared/assets/icons/smile.svg';

import styles from './Navigation.module.scss';
import {Link} from 'react-router-dom';
import {SiAntismile} from 'figma/components/SI/Icons/SI-antismile/SiAntismile';
import { LoyaltyService } from 'entities/Loyalty';

export type NavItem = {
  title: string;
  icon: JSX.Element | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  path?: Path;
  isAuth: boolean;
};

function Navigation(props: {
  authService: AuthService,
  loyaltyService: LoyaltyService,
  profileService: ProfileService,
  orderService: OrderService,
  preferencesService: PreferencesService,
  onMobileMenuOpen?: (isMenuOpen: boolean) => void;
}) {
  const { t } = useTranslation();
  const authenticated = useBehaviorSubject(props.authService.authenticated);
  // useEffect(() => {
  //   props.authService.authenticated.next(props.authService.isAuthorized)
  // }, [authenticated])

  const authState = useBehaviorSubject(props.authService.state)

  const behaviorOrder = useBehaviorSubject(props.orderService.behaviorOrder)
  const {data: currentOrder, loading} = useServiceData(() => props.orderService.getCurrentOnly(), [behaviorOrder, authenticated])
  const { width } = useWindowSize()
  const [isMenuActive, setMenuActive] = useState(false)
  const [loginVisible, setLoginVisible] = useState(false)

  const minReserved = currentOrder && minDate<OrderItemDescriptor>(currentOrder.items, item => item.reservedUntil!)
  const count = currentOrder && currentOrder.items.length
  const isMobile = width < 840;

  useEffect(() =>{
    if(authState === AuthorizationState.REQUIRE_AUTHORIZATION){
      setLoginVisible(true);
    }
  }, [authState])

  const navItems: NavItem[] = [
    { title: `${t('header.navigation.0')}`, icon: <IconWithCounter icon={<ShoppingCart />} count={count}/>, path: Path.CART, isAuth: true},
    { title: `${t('header.navigation.1')}`, icon: <Ticket />, path: Path.SHOP, isAuth: true },
    { title: `${t('header.navigation.2')}`, icon: <Smile/>, isAuth: false, onClick: () => setLoginVisible(true)},
    { title: `${t('header.navigation.3')}`, icon: <Smile/>, isAuth: true, path: Path.ACCOUNT},
    { title: `${t('header.navigation.4')}`, icon: <SiAntismile/>, isAuth: true, onClick: () => props.authService.logout()},
  ]

  const navMenuList = authenticated ? navItems.filter((item: NavItem ) => item.isAuth === true) : navItems.filter((item: NavItem ) => item.isAuth === false)

  if (loading) <></>

  return (
    <div className={styles.itemsContainer}>
      <nav className={styles.navigation}>
        <ul className={styles.isMobile}>
          {minReserved && <CountdownTimerOnRxJS date={minReserved}/>}
          {isMobile ?
            <>
              {authenticated &&
                <li className={styles.mobileMenu}>
                  <Link to={Path.CART}>
                    <IconWithCounter icon={<ShoppingCart/>} count={count}/>
                  </Link>
                </li>
              }
              <li className={classNames(styles.mobileMenu, styles.burger)}>
                <BurgerIcon active={isMenuActive}
                  onClick={() => {
                    props.onMobileMenuOpen && props.onMobileMenuOpen(!isMenuActive);
                    setMenuActive(!isMenuActive)
                  }
                }/>
              </li>
              <MobileNavigation visible={isMenuActive}>
                <NavList items={navMenuList} onClick={() => {
                  props.onMobileMenuOpen && props.onMobileMenuOpen(!isMenuActive);
                  setMenuActive(!isMenuActive)
                }}/>
              </MobileNavigation>
            </> :
            <NavList items={navMenuList}/>
          }
        </ul>
      </nav>
      <DelayUnmountChild
        isMountChild={loginVisible}
        delayTime={300}
      >
        <Modal
          visible={loginVisible}
          closeModal={() => {
            setLoginVisible(false);
            props.profileService.resetRegistration();
            if(authState === AuthorizationState.REQUIRE_AUTHORIZATION){
              if(!authenticated){
                props.authService.state.next(AuthorizationState.NOT_AUTHORIZED)
              }
            }
          }}
          outsideClickClose={false}
        >
          <AuthWithSwitch
            loyaltyService={props.loyaltyService}
            authService={props.authService}
            profileService={props.profileService}
            preferencesService={props.preferencesService}
            onFinish={() => {
              setLoginVisible(false);
              props.profileService.resetRegistration();
              if(authState === AuthorizationState.REQUIRE_AUTHORIZATION){
                if(!authenticated){
                  props.authService.state.next(AuthorizationState.NOT_AUTHORIZED)
                }
              }
            }}
          />
        </Modal>
      </DelayUnmountChild>
    </div>
  );
}

export default Navigation;
