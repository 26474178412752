import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { deserialize } from '@sebbia/object-deserializer';
import { ID, moneyMapper } from 'shared/model';
import { Money } from 'shared/utils';
import { loyaltyDescriptorDeserializer, loyaltyOrderDeserializer, loyaltyTransactionDeserializer } from './deserializers';
import { LoyaltyDescriptor, LoyaltyService, LTY_TransactionQuery, Tickets_LoyaltyOrderCheckResult } from './types';

class LoyaltyServiceImpl implements LoyaltyService {
  constructor(private client: ApolloClient<NormalizedCacheObject>) {}
  async createReferral(link: string): Promise<void> {
    const CREATE_REFERRAL = gql`
      mutation createReferral($data: ReferralInput!) {
        referral {
          createReferral(data: $data) {
            enteredAt
          }
        }
      }
    `;
    await this.client.mutate({
      mutation: CREATE_REFERRAL,
      variables: {
        data: { 
          link 
        },
      },
    });
    return Promise.resolve();
  }

  async getLoyaltyDescriptor(): Promise<LoyaltyDescriptor> {
    const query = gql`
      query getLoyalty {
        loyalty {
          loyaltyDescriptorQuery {
            getDescriptor {
              id
              bonusActivePeriodDays
              bonusPriceCoverPercentage
              maxExpenditurePerTransactionPercentage
              applicableWithDiscount
              legalityDocumentationLink
              isActive
              accrualPercentage
              loyaltyCurrencyName {
                plural
                mod1
                mod24
                mod50
              }
            }
          }
        }
      }
    `;

    const res = await this.client.query({ query });
    return deserialize(res.data, o =>
      o
        .required('loyalty.loyaltyDescriptorQuery.getDescriptor')
        .asObject(o => loyaltyDescriptorDeserializer(o))
    );
  }

  async getCoins(userId?: ID): Promise<Money> {
    const query = gql`
    query getCoins($userId: ID) {
      loyalty {
        transactionQuery {
          getUserBonusCount(userId: $userId)
        }
      }
    }
  `;

    const res = await this.client.query({variables: {userId: userId}, query });
    return deserialize(res.data, o =>
      o
        .required('loyalty.transactionQuery.getUserBonusCount')
        .as(moneyMapper)
    );
  }

  async checkLoyaltyOrder(orderId: ID, userId?: ID): Promise<LTY_TransactionQuery & Tickets_LoyaltyOrderCheckResult> {
    const query =  gql`
    query checkLoyaltyOrder($orderId: ID!, $userId: ID) {
      order {
        checkLoyaltyOrder(orderId: $orderId) {
          isApplicable
          applicabilityError
          loyaltyAccrued
          maxPossibleExpenditure
          loyaltySpent
        }
      }
    loyalty{
      transactionQuery {
          getUserBonusCount(userId: $userId)
        }
      }
    }
  `;

  const res = await this.client.query({ variables: {orderId: orderId, userId: userId}, query });
  const loyaltyOrder = deserialize(res.data, o =>
    o
      .required('order.checkLoyaltyOrder')
      .asObject(o => loyaltyOrderDeserializer(o))
    );
  const loyaltyCount = deserialize(res.data, o =>
    o
      .required('loyalty.transactionQuery')
      .asObject(o => loyaltyTransactionDeserializer(o))
  );
  return {...loyaltyOrder, ...loyaltyCount}
  }
}

export default LoyaltyServiceImpl;
